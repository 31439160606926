import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Product } from '../models/product';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-product-show',
  templateUrl: './product-show.page.html',
  styleUrls: ['./product-show.page.scss'],
})
export class ProductShowPage implements OnInit {

  @Input() productId: number;
  @Input() product: Product;
  isModal = true;

  slidesOptions = {
    initialSlide: 0,
    loop: true,
    autoplay: {
      delay: 4000
    }
  };

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    if (! this.productId) {
      this.productId = Number(this.route.snapshot.paramMap.get('id'));
      this.isModal = false;
    }

    this.productService.onReady(
      () => this.productService.fetch(this.productId).subscribe(
        (product) => this.product = product
      )
    );
  }

  dismiss() {
    this.modalController.dismiss();
  }

}
